/* eslint-disable max-len */
import React from 'react';

const BuyCarcassesIcon = () => (
  <svg className="dashboard-icon" viewBox="0 0 141.67 133.72">
    <path className="background" d="M148.83,74.75c-10.41,9.39-22.46,12.89-34.74,13.84l8.79-14.75a25.59,25.59,0,1,0-44,0L87.3,87.91c-19.92-1.65-37.63-3.58-46.57,9.93h0c-7.45,10.65-8.48,48.47-5.72,54l7.3-3.69c-.21,3.15.12,2.59-.81,15.24,0,0,11.19,8.66,18.44-6.29,2.62-6.19,5.52-12,7.12-16.63,31.26,25.3,61.86-17.1,69.14,21.72,2.43.65,12.78.65,12.94-9.16,0,0,0-.05,0-.09,1.8-23.52,11.56-35.94,20-43.38v0C182.33,80.77,148.83,74.75,148.83,74.75Z" transform="translate(-32.04 -33.63)"/>
    <path className="line" d="M100.9,49.13a10.58,10.58,0,1,0,10.58,10.58A10.59,10.59,0,0,0,100.9,49.13Zm0,18.16a7.58,7.58,0,1,1,7.58-7.58A7.59,7.59,0,0,1,100.9,67.29Z" transform="translate(-32.04 -33.63)"/>
    <path className="line" d="M171.49,87.79c-6-11.45-21.72-14.4-22.39-14.52l-.72-.13-.55.49c-8.34,7.52-18.23,11.75-30.94,13.19l7.28-12.21h0a27.09,27.09,0,1,0-46.54,0l6.89,11.56c-17.45-1.45-33.89-2.59-43.27,8.48l0-.12-1,1.49c-.21.29-.42.58-.62.89l0,.07c-7.69,11-9,49.09-5.83,55.5l2.68-1.34c-2.32-4.63-1.79-40.49,5.19-51.81l.44-.66c8.17-12.36,24.47-11,43.35-9.42l1.08.09,12.11,20.33a2.94,2.94,0,0,0,1,1,2.82,2.82,0,0,0,1.41.39,2.72,2.72,0,0,0,.69-.09,2.76,2.76,0,0,0,1.7-1.27L115,90c14-1.22,25.19-5.68,34.27-13.65,3,.69,15,3.94,19.57,12.82,2.79,5.34,2.47,11.87-.94,19.43-12.1,10.84-18.71,25.22-20.2,43.93l-.05.43a7.33,7.33,0,0,1-2.73,6.06,11.44,11.44,0,0,1-7.44,1.81c-1.58-7.71-4.17-12.55-8.12-15.18a12.29,12.29,0,0,0-2.17-1.16l0-.07A17.08,17.08,0,0,1,118,132.13l-3,.37a20.16,20.16,0,0,0,5.65,10.86,64.58,64.58,0,0,0-12.56,1.87c-11.91,2.53-25.38,5.38-39.25-5.29a106.52,106.52,0,0,0,4.4-15.17l-3-.55A99,99,0,0,1,66.37,138l0,0-.7,2c-1,2.84-2.5,6.21-4.1,9.77-1,2.18-2,4.44-2.95,6.7-2.13,4.38-4.78,7-7.87,7.71a10.6,10.6,0,0,1-7.66-1.53l.14-1.83C44.8,139.42,45,128.84,42,126.46l-1.84,2.37c2.3,2,.74,22.82.07,31.71l-.25,3.4.49.48a14.62,14.62,0,0,0,8.38,2.93,11.07,11.07,0,0,0,1.88-.15c4.33-.73,7.89-3.92,10.62-9.55,1-2.29,2-4.53,2.95-6.7,1.29-2.85,2.52-5.59,3.49-8.07,14.71,10.86,29.22,7.78,41,5.29,7.72-1.64,14.38-3.06,19,0,3.35,2.23,5.65,6.9,7,14.27l.18.93.91.24a14.24,14.24,0,0,0,10.91-2.18,10.25,10.25,0,0,0,3.91-8.17l0-.23c1.4-18.14,7.76-32,19.46-42.38l.28-.25.11-.29C174.43,101.56,174.76,94.05,171.49,87.79Zm-70.59,20L80.2,73.07a24.11,24.11,0,0,1,20.65-36.44,24.22,24.22,0,0,1,6,.75,24.09,24.09,0,0,1,14.78,35.69Z" transform="translate(-32.04 -33.63)"/>
  </svg>
);

export default BuyCarcassesIcon;
